<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showMenu="true"/>
		<AppTitle title="Feedback"/>
		<div class="pt-8 overflow-y-scroll flex-grow">
			<form @submit.stop.prevent="submit">
				<p class="pb-4">
					Share your feedback. It allows us to constantly improve the experience.
				</p>
				<div>
					<textarea class="block w-full py-4 px-6 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" rows="4" placeholder="Enter comments" v-model="form.feedback"></textarea>
				</div>
				<alert
					class="mt-4"
					type="success"
					message="Thank you for submitting your feedback!" 
					v-if="success"
					@dismiss="success=false"
				/>
				<input type="submit" value="Update" class="mt-8 bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg"/>
			</form>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import Alert from '@/components/ui/Alert.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
			Alert,
		},
		data(){
			return {
				error_message: '',
				loading: false,
				success: false,
				form: {
					feedback: '',
				},
				errors: {
					feedback: false,
				}
			}
		},
		methods: {
			validateForm(){
				let is_valid = true;
				this.error_message = '';
				if (this.form.feedback.length === 0){
					this.errors.feedback = true;
					is_valid = false;
				}
				return is_valid;
			},
			submit(){
				if (!this.validateForm()) return;
				this.loading = true;
				this.$store.dispatch('submitFeedback', {
					feedback: this.form.feedback
				}).then( () => {
					this.loading = false;
					this.success = true;
					this.form.feedback = "";
				});
			}
		}
	}
</script>